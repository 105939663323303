
@font-face {
    font-family: 'Belleza';
    src: url('/public/fonts/Belleza-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('/public/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('/public/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: Bold;
  font-style: Bold;
}
  
