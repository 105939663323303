

.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    background-color: #333;
    color: #fff;

    padding: 0 1rem;
}

.navbar-footer-info {
    display: flex;
    flex-direction: column;
    padding: 2.3vw 3.5vh;
    font-size: 2.1vh;
}

.text-info {
    padding-bottom: 1vh;
}

.last-info{
    padding-bottom: 0;
}

@media (max-width: 1570px) {
    .navbar-footer-info {
        font-size: 0.95rem;
    }
}

@media (max-width: 1200px) {
    .navbar-footer-info {
        font-size: 0.9rem;
    }
}

@media (max-width: 390px) and (max-height: 950px) and (orientation: portrait) {
    .navbar-footer-info {
        font-size: 0.8rem;
    }
  }
  
  @media (max-width: 932px) and (max-height: 430px) and (orientation: landscape) {
    .navbar-footer-info {
        font-size: 0.8rem;
    }
}
