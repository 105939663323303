
.hero-section {
    max-width: 100vw;
    min-height: 105vh;
    height: auto;
    max-height: 105vh;
    position: relative;
    background-color: #111312;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    overflow: hidden;
}

.telescop-image {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 2%;
    width: 100%;
    height: 100%;
    background-image: url('/public/images/telescop.png');
    background-size: cover;
    background-position: center 60%;
    background-repeat: no-repeat;
    z-index: 1;
}

.heroTitle {
    font-family: 'Roboto-Bold';
    font-size: 7.8vw;
    /* font-weight: 700; */
    line-height: 1.1;
    margin: 0;
}

.hero-content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: -13vh;
    /* max-width: 50%; */
    height: 100%;
    padding-left: 4.5vw;
    z-index: 2;
}

.hero-content p {
    width: 33vw;
    font-size: 1.75vw;
    line-height: 1.8;
    margin-bottom: 33.5vh;

    text-shadow: -1.4px -1px 1px rgb(0, 0, 0);
}

@media (max-width: 1570px) {
    .hero-section {
        min-height: 117vh;
    }

    .telescop-image {
        background-position: center 63%;
    }

    .hero-content {
        padding-left: 4.5vw;
        margin-bottom: -12vh;
    }

    .hero-content p {
        margin-bottom: 37.5vh;
    }
}

@media (max-width: 1350px) {
    .hero-section {
        min-height: 94vh;
    }

    .telescop-image {
        background-position: center 63%;
    }

    .hero-content {
        padding-left: 4.5vw;
        margin-bottom: -9vh;
    }

    .hero-content p {
        margin-bottom: 30.5vh;
    }
}


@media (max-width: 1200px) {
    .hero-section {
        min-height: 65vh;
    }

    .telescop-image {
        background-position: center 63%;
    }

    .hero-content {
        padding-left: 4.5vw;
        margin-bottom: -4vh;
    }

    .hero-content p {
        margin-bottom: 22.5vh;
    }
}

@media (max-width: 1150px) {
    .hero-section {
        min-height: 61vh;
    }

    .hero-content p {
        margin-bottom: 20.5vh;
        font-size: 1.3rem;
        line-height: 1.4;
    }

    .hero-content p br {
        display: none;
    }
}

@media (max-width: 815px) {
    .hero-section {
        min-height: 45vh;
    }

    .hero-content p br {
        display: none;
    }

    .hero-content p {
        width: 29vw;
        margin-bottom: 10.5vh;
        font-size: 0.94rem;
        line-height: 1.4;

        text-shadow: -0.4px -0.4px 1px rgb(0, 0, 0);
    }
}

@media (max-width: 700px) {
    .hero-content p {
        width: 30vw;
    }
}

@media (max-width: 420px) {
    .hero-section {
        min-height: 33vh;
    }

    .hero-content {
        margin-bottom: 0vh;
    }

    .hero-content p {
        width: 33vw;
        margin-bottom: 10.5vh;
        font-size: 0.7rem;
        line-height: 1.4;

        text-shadow: -0.4px -0.4px 1px rgb(0, 0, 0);
    }

    .hero-content p br {
        display: none;
    }

}

@media (max-width: 850px) and (max-height: 1300px) and (orientation: portrait) {
    .hero-section {
        min-height: 40vh;
    }

    .hero-content {
        margin-bottom: -7vh;
    }

    .hero-content p {
        margin-bottom: 15.5vh;
    }
}

@media (max-width: 450px) and (max-height: 950px) and (orientation: portrait) {
    .hero-section {
        min-height: 40vh;
    }

    .hero-content {
        margin-bottom: -3vh;
    }

    .heroTitle {
        font-size: 9.9vw;
    }
}
  
@media (max-width: 932px) and (max-height: 430px) and (orientation: landscape) {
    .hero-section {
        min-height: 121vh;
    }

    .hero-content {
        margin-bottom: -20vh;
    }

    .hero-content p {
        width: 29vw;
        margin-bottom: 35.5vh;
        font-size: 1rem;
        line-height: 1.4;

        text-shadow: -0.4px -0.4px 1px rgb(0, 0, 0);
    }
}

