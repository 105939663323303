
@import './css/fonts.css';


html {
  box-sizing: border-box;
}

*, *::before, *::after {
  box-sizing: inherit;
}

img {
  width: 100%;
  max-width: 100%;
  vertical-align: middle;
}

body {
  margin: 0;
  font-family: 'Belleza', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 100vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.backgroundRadius {
  background-color: #111312;
}

.componentsContainer {
  background-color: #fff;
  padding-left: 5vw;
  padding-right: 5vw;
  border-radius: 5vw 5vw 0 0;
  -webkit-border-radius: 5vw 5vw 0 0;
  -moz-border-radius: 5vw 5vw 0 0;
  -ms-border-radius: 5vw 5vw 0 0;
  -o-border-radius: 5vw 5vw 0 0;
}

h2 {
  font-size: 4.7vh;
  font-weight: 600;
  line-height: 5.6vh;

  margin: 0;
}

p,
.team-content ul li {
  /* font-size: 2vh; */
  font-size: 1.25rem;
  /* line-height: 2.5vh; */
  line-height: 1.813rem;
  margin: 0;
}

.team-content ul li {
  margin-bottom: 0 !important;
}

@media (max-width: 1850px) {
  h2 {
    font-size: 3.65vh;
    line-height: 4.5vh;
  }
  
  p,
  .team-content ul li {
    /* font-size: 1.6vh; */
    line-height: 2.3vh;
  }
}

@media (max-width: 1570px) {
  h2 {
    font-size: 2.1rem;
    line-height: 2.9rem;
  }
  
  p,
  .team-content ul li {
    font-size: 1.27rem;
    line-height: 1.6rem;
  }
}

@media (max-width: 1370px) {
  h2 {
    font-size: 3.55vh;
    line-height: 4.3vh;
  }
  
  p,
  .team-content ul li {
    font-size: 2.1vh;
    line-height: 2.5vh;
  }
}

@media (max-width: 1200px) {
  h2 {
    font-size: 1.8rem;
    line-height: 2.5rem;
  }

  p,
  .team-content ul li {
    font-size: 1.1rem;
    line-height: 1.4rem;
  }
}

@media (max-width: 1010px) {
  h2 {
    font-size: 1.8rem;
    line-height: 2.5rem;
  }
  
  p,
  .team-content ul li {
    font-size: 1.1rem;
    line-height: 1.4rem;
  }
}

@media (max-width: 600px) {
  h2 {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
  
  p,
  .team-content ul li {
    font-size: 1.01rem;
    line-height: 1.12rem;
  }
}

/* @media (max-width: 850px) and (orientation: landscape) {
  h2 {
    font-size: 1.42rem;
    line-height: 1.8rem;
  }
  
  p,
  .team-content ul li {
    font-size: 0.9rem;
    line-height: 1.12rem;
  }
} */

@media (max-width: 380px) and (max-height: 850px) and (orientation: portrait) {
  h2 {
    font-size: 1.42rem;
    line-height: 1.8rem;
  }
  
  p,
  .team-content ul li {
    font-size: 1rem;
    line-height: 1.12rem;
  }
}

@media (max-width: 932px) and (max-height: 430px) and (orientation: landscape) {
  h2 {
    font-size: 1.42rem;
    line-height: 1.8rem;
  }
  
  p,
  .team-content ul li {
    font-size: 1rem;
    line-height: 1.12rem;
  }
}
