

.container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin: 0 auto;
    max-width: 100vw;
    /* max-height: 80vh; */
}

.content {
    width: 55%;
    padding-right: 6vw;
}

.features-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.features-list li {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.1vh 0;
    border-bottom: 1px solid #242B35;
}

.features-list li:first-child {
    border-top: 1px solid #242B35;;
}

.process-number {
    font-size: 3.5vw;
    font-weight: 600;
    margin-right: 2vw;
    padding-bottom: 0.5vw;
    padding-left: 0vw;
    color: #333;
    width: 5vw;
    height: 5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #242B35;
    border-radius: 50%;
    flex-shrink: 0;
}

.process-text {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
}

.process-text h2 {
    margin: 0;
    margin-bottom: 0.5vh;
    text-transform: uppercase;
    color: #333;
}

.process-text p {
    margin: 0.5vh 0 0;
    /* font-size: 1.2vw; */
    color: #666;
}

.imageProcess {
    width: 45%;
    display: flex;
    align-items: center;
    justify-content: center;

    object-fit: cover;
}

.imageProcess img {
    object-fit: cover;
    object-position: 30% 0;
    min-height: 100%;
}


@media (max-width: 1100px) {
    .container {
        flex-direction: column-reverse;
        align-items: center;
    }

    .content {
        width: 100%;
        padding: 0 1rem;
    }
   
    .content h2 {
        margin-bottom: 1rem;
    }

    .imageProcess {
        width: 70%;
        /* padding-top: 4rem; */
        padding-left: 1rem;
        padding-right: 1rem;
        margin-bottom: 3rem;
    }

    .imageProcess img {
        object-position: 0 0;
    }

    .features-list li {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    .process-text {
        display: flex;
        margin-top: 0rem;
    }

    .process-text h2 {
        margin-left: 0rem;
        margin-bottom: -0.3rem;
        padding-top: 0;
    }

    .process-text p {
        margin-top: 0.5rem;
        margin-left: 0rem;
    }
}

@media (max-width: 815px) {
    .content {
        width: 45%;
        padding-right: 4vw;
    }
   
    .content h2 {
        margin-bottom: 1rem;
    }

    .imageProcess {
        width: 55%;
    }

    .imageProcess img {
        object-position: 57% 0;
    }
    
    .process-text {
        display: flex;
        margin-top: -0.2rem;
    }

    .process-text h2 {
        margin-left: 0.5rem;
        margin-bottom: -0.3rem;
        padding-top: 0.5rem;
    }

    .process-text p {
        margin-top: 0.9rem;
        /* margin-left: -3.5rem; */
    }
}

@media (max-width: 755px) {
    .process-number {
        display: none;
    }

    .process-text {
        display: flex;
        margin-top: 0rem;
    }

    .process-text h2 {
        margin-left: 0rem;
        margin-bottom: -0.3rem;
    }

    .process-text p {
        margin-top: 0.5rem;
        margin-left: 0rem;
    }
}


@media (max-width: 850px) and (max-height: 1300px) and (orientation: portrait) {
    .container {
        flex-direction: column-reverse;
        align-items: center;
    }

    .content {
        width: 90%;
        padding-right: 4vw;
    }

    .imageProcess {
        width: 80%;
        margin-bottom: 3rem;
        padding-top: 0;
    }

    .process-number {
        width: 7vw;
        height: 7vw;

        font-size: 4.5vw;
    }
}

@media (max-width: 950px) and (max-height: 450px) and (orientation: landscape) {
    .container {
        flex-direction: column-reverse;
        align-items: center;
    }

    .content {
        width: 90%;
        padding-right: 0;
    }

    .imageProcess {
        width: 70%;
        margin-bottom: 4rem;
        padding-top: 0;
    }

    .process-number {
        width: 7vw;
        height: 7vw;

        font-size: 4.5vw;
    }

    .features-list li {
        align-items: center;
    }

    .process-text p {
        margin-left: 0;
    }

    .process-text h2 {
        margin-left: 0;
    }
}


