/* 
.carousel-container {
    width: 100%;
    margin: 0 auto;
    background-color: #D0BAA4;
    position: relative;
    padding: 0 9rem;
}

.carousel-image {
    object-fit: cover;

    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.arrow-container {
    width: 60px;
    height: 60px;
    background-color: #333;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: -4rem;
    margin-left: -4rem;
}

.arrow {
    border-top: 18px solid transparent;
    border-bottom: 18px solid transparent;    

    cursor: pointer;    
}

.next-arrow {
    border-left: 25px solid #fff;

    margin-right: -0.5rem;
}

.previous-arrow {
    border-right: 25px solid #fff;

    margin-right: 0.5rem;;
}

.slick-next::before{  
    display: none;
}
.slick-prev::before{  
    display: none;
}

.arrow-container:hover {
    background-color: #555;
}

@media (max-width: 640px) { 

    .carousel-container {
        padding: 0 2rem;
    }

    .arrow-container {
        width: 40px;
        height: 40px;
        margin-right: 1rem;
        margin-left: 1rem;
        z-index: 20;
    }

    .arrow {
        border-top: 12px solid transparent;
        border-bottom: 12px solid transparent;
    }

    .next-arrow {
        border-left: 15px solid #fff;
        margin-right: -0.3rem;
    }

    .previous-arrow {
        border-right: 15px solid #fff;
        margin-right: 0.3rem;
    }

    .carousel-image {
        padding-top: 1rem;
        padding-bottom: 1rem;
        padding-right: 3rem;
        padding-left: 3rem;
        width: 140%;
        height: auto;
    }  
} */


.images-container {
    max-width: 100%;
    display: flex;
    flex-direction: row;
    gap: 3rem;

    margin: 0 auto;
    background-color: #D0BAA4;
    /* position: relative; */
    padding: 3rem 9rem;
    /* margin-bottom: 5rem; */
}

@media (max-width: 1100px) {
    .images-container {
        padding: 3rem 5vw;
    }

    .imgHiddenKnight {
        display: none;
    }
}



@media (max-width: 625px) {
    .images-container {
        padding: 3rem 5vw;
    }

    .imageSectionFourth {
        padding: 0 1rem;
    }

    .imgHiddenKnight,
    .imgHiddenLabak {
        display: none;
    }
}

@media (max-width: 1200px) and (max-height: 850px) and (orientation: landscape) {
    .images-container {
        padding: 3rem 5vw;
    }

    .imgHiddenKnight {
        display: none;
    }
}
