
.team-section {
    display: flex;
    background-color: #2a2a2a;
    color: #ffffff;
}

.team-image {
    flex: 1.5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.team-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.team-content {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 5vh 6vw 5vh 6vw;
}

.team-content h2 {
    /* font-size: 4.8vh;
    font-weight: 600;
    line-height: 5.6vh; */
    margin-bottom: 1rem;
}

.team-content p {
    /* font-size: 2.1vh;
    line-height: 2.8vh; */
    margin-bottom: 1.5rem;

    text-align: justify;
    text-justify: inter-word;
}

.team-content ul {
    list-style: none;
    padding: 0;
    margin: 0 0 1.5rem 0;
}

.team-content ul li {
    /* font-size: 1rem; */
    margin-bottom: 0.5rem;
}

@media (max-width: 550px) {
    .team-section {
        flex-direction: column;
    }
}
