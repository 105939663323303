
.section-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    text-align: justify;
    text-align-last: center;

    width: 100%;
    padding: 5rem 0vw;
    text-align: center;
    color: #242B35;
    
}

.section-text p {
    text-align: justify;
    text-justify: inter-word;
}

.section-text h2 {
    margin-bottom: 3vh;
    /* margin-top: 2vh; */
}

.accuracy .section-content{
    width: 49vw;
}

.technology .section-content{
    width: 62vw;
}

.technology .section-content > :last-child {
    padding-bottom: 5rem;
}

.textBox {
    padding-bottom: 4rem;
}

.technologyTitle{
    width: 80vw;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.technologyTitle h1 {
    font-family: 'Roboto-Bold';
    font-size: 3.8vw;
    font-weight: 700;
    /* margin-bottom: 4.5vh; */
    margin: 0;
}

@media (max-width: 800px) {
    .accuracy .section-content{
        width: 70vw;
    }

    .technology .section-content{
        width: 70vw;
    }
}

@media (max-width: 420px) {
    .accuracy .section-content{
        width: 70vw;
    }

    .technology .section-content{
        width: 70vw;
    }

    .technologyTitle h1 {
        font-size: 4.8vw;
    }
}
