
.features-container {
    height: auto;
    
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 5rem;
    padding-bottom: 5rem;

    background-color: #fff;
    color: #111312;
}


.first-boxes {
    min-height: 64vh;
}

.featureContainer {
    height: 100%;
    max-width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;
    /* padding: 0.1vh 0.1vw; */
}

.third-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.third-container h2 {
    margin-top: -30px;
}

.first-text,
.third-text {
    min-height: 34vh;
    height: auto;
}

.feature {
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.feature h2 {
    margin-bottom: 5vh;
}

.feature-text {
    background-color: #F8F8F8;
    padding: 4vh 2vw;
    text-align: justify;
    text-justify: inter-word;
    border-radius: 2.25rem;
    -webkit-border-radius: 2.25rem;
    -moz-border-radius: 2.25rem;
    -ms-border-radius: 2.25rem;
    -o-border-radius: 2.25rem;
}

.second-text-height {
    min-height: 34vh;
}

.feature p span {
    display: block;
    margin-top: 1rem;
    color: #333;
}

.text-left  {
    text-align: left;
    align-items: flex-start;
}

.color-text {
    background-color: #D0BAA4;
    color: #fff;
}


@media (max-width: 1350px) {
    .second-text-height {
        min-height: 26vh;
    }

    .feature h2 {
        width: 100%;
    }
}

@media (max-width: 1150px) {
    .featureContainer {
        max-width: 31%;
    }
    
    .second-text-height {
        min-height: 31.5vh;
    }

    .feature h2 {
        width: 110%;
    }

    .third-container h2{
        /* margin-left: 1.5vw; */
    }
}


@media (max-width: 815px) {
    .featureContainer {
        max-width: 80%;
        padding-bottom: 4rem;
    }

    .features-container {
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* padding-top: 5vh;
        padding-bottom: 5.1vh; */

        background-color: #fff;
        color: #111312;
    }

    .features-container > :last-child {
        padding-bottom: 0;
    }

    .feature h2 {
        width: 100%;
        margin-bottom: 3vh;
    }

    .second-text-height {
        min-height: auto;
      }

    .third-container h2{
        margin-left: 0;
        margin-top: 0;
    }

    .feature h2 br {
        display: none;
    }
    
    .feature-text {
        text-align: justify;
        text-justify: inter-word;
    }

    .first-text {
        min-height: auto;
    }

    .third-text {
        min-height: auto;
        height: auto;
    }

    .second-text-height {
        min-height: auto;
    }

    .text-left {
        text-align: center;
    }
}


@media (max-width: 625px) { 
    .featureContainer {
        max-width: 90%;
    }

    .feature-text {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}

@media (max-width: 850px) and (max-height: 1300px) and (orientation: portrait) {
    .features-container > :last-child {
        padding-bottom: 0;
    }
}

@media (max-width: 1500px) and (max-height: 1000px) {
    .second-text-height {
        min-height: 29vh;
    }
}

@media (max-width: 815px) and (max-height: 1000px) {
    .second-text-height {
        min-height: auto;
    }
}

@media (min-width: 815px) and (max-width: 1100px) and (max-height: 1400px) and (orientation: portrait) {
    .second-text-height {
        min-height: 23vh;
    }
}

@media (max-width: 810px) and (max-height: 1300px) and (orientation: portrait) {
    .second-text-height {
        min-height: auto;
    }
}

@media (max-width: 500px) and (max-height: 1000px) and (orientation: portrait) {
    .second-text-height {
        min-height: auto;
    }
}

@media (min-width: 815px) and (max-height: 550px) and (orientation: landscape) {
    .second-text-height {
        min-height: 55vh;
    }
}
